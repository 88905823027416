<template>
    <div>
        <van-field
            readonly
            clickable
            :name="this.name"
            :value="value_name"
            :label="this.lable"
            placeholder="点击选择"
            input-align="right"
            @click="show_picker = true"
        />
        <van-popup v-model="show_picker" position="bottom">
        <van-search v-model="search" shape="round" placeholder="请输入搜索关键词" @input="onSearch" />
        <van-picker
            value-key="name"
            show-toolbar
            :columns="this.arr"
            @confirm="confirm"
            @cancel="cancel"
        />
        </van-popup>
    </div>
</template>

<script>
export default {
    name:'',
    data(){
        return {
            value_name: '',
            show_picker: false,
            arr: [],
            search: ''
        }
    },
    computed:{},
    methods:{
        confirm(e) {
            this.$emit('clk',e)
            this.value_name = e.name
            this.show_picker = false
            this.search = ''
            this.arr = this.farr
        },
        cancel() {
            this.show_picker = false
            this.search = ''
            this.arr = this.farr
        },
        onSearch(e) {
            this.arr = this.farr.filter( v => {
                return v.name.indexOf(e) > -1
            })
        }
    },
    filters:{},
    props:{
        name: {
            type: String,
            default() {
                return 'name'
            }
        },
        lable: {
            type: String,
            default() {
                return 'label'
            }
        },
        farr: {
            type: Array,
            default() {
                return []
            }
        },
        fvalue_name: {
            type: String|Number,
            default() {
                return ''
            }
        }
    },
    created(){
        this.value_name = this.fvalue_name
        this.arr = this.farr
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        farr(newVal) {
            this.arr = newVal
        }
    }
}
</script>

<style>
</style>