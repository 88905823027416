//格式化数字，保留两位小数
export const fomatFloat = (num) => {
    let f = parseFloat(num);
    if (isNaN(f)) {
        return false
    }

    f = Math.round(num*100)/100
    let s = f.toString()
    let rs = s.indexOf('.')
    if(rs < 0) {
        rs = s.length
        s += '.'
    }
    while (s.length <= rs + 2) {
        s += '0'
    }

    return s
}

//千位符
export const thousandBitSeparator = (num) => {
    let source = num.toString().split(".");
	source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)','ig'),"$1,");
	return source.join(".");
  }

  //日期加减计算
export const dateChange = (num = 1,date = false) => {
    if (!date) {
        date = new Date();//没有传入值时,默认是当前日期
        date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }
    date += " 00:00:00";//设置为当天凌晨12点
    date = Date.parse(new Date(date))/1000;//转换为时间戳
    date += (86400) * num;//修改后的时间戳
    return new Date(parseInt(date) * 1000);//转换为时间
}

//日期格式化
export const formatDate = (date) => {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
}

//日期时间格式化
export const formatTime = (time) => {
    return time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate() + ' ' + time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds()
}