<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div>
            <van-cell-group inset >
                <van-cell title="业务付款单号" :value="details.name" />
                <van-cell title="审批状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell title="申请企业" :value="details.enterprise_name" />
                <van-cell title="申请部门" :value="details.department_name" />
                <van-cell title="申请人" :value="details.zhwl_arr.zhwl_operator_name" />
                <van-cell title="发起人" :value="details.zhwl_arr.zhwl_apply_staff_name" />
                <van-cell title="备注" :value="details.note" />
                <van-cell title="付款编号" :value="details.zhwl_arr.zhwl_yw_id" />
                <van-cell title="付款单位" :value="details.zhwl_arr.zhwl_collection_entertain_name" />
                <van-cell title="收款单位" :value="details.zhwl_arr.zhwl_pay_entertain_name" />
                <van-cell title="内外贸" :value="details.zhwl_arr.zhwl_domestic_foreign" />
                <van-cell title="付款币种" :value="details.zhwl_arr.zhwl_currency" />
                <van-cell title="付款金额" :value="details.zhwl_arr.zhwl_money | diy_money" />
                <van-cell title="付款方式" :value="details.zhwl_arr.zhwl_pay_type" />
                <van-cell title="最晚付款日期" :value="details.zhwl_arr.zhwl_pay_time | diy_date" />
                <van-cell title="开户行" :value="details.zhwl_arr.zhwl_bank" />
                <van-cell title="银行卡号" :value="details.zhwl_arr.zhwl_card" />
                <van-cell title="付款用途" :value="details.zhwl_arr.zhwl_note" />
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="withdraw_auth" round block type="danger" @click="BusinessPaymentWithdraw()">审批撤回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { 
    business_payment_details_request,
    business_payment_to_approval_request,
    business_payment_withdraw_request
    } from '@/network/finance/BusinessPayment.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'BusinessPaymentDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            edit: false,
            id: 0,
            details: {
                zhwl_arr: {}
            },
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false,
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改业务付款单' : '新增业务付款单') : '业务付款单详情'
        }
    },
    methods:{
        get_business_payment_details() {
            this.$store.commit('true_loading')
            business_payment_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        back_details() {
            this.get_business_payment_details()
            this.edit = false
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 10,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                business_payment_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_business_payment_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        BusinessPaymentWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                business_payment_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_business_payment_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        is_auth() {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false
            
            if(this.details.state === 0) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交业务付款申请')
                    this.edit_auth = this.$_has('修改业务付款申请')
                    this.del_auth = this.$_has('删除业务付款申请')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回业务付款申请')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批业务付款申请')
                }
                this.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交业务付款申请')
                    this.edit_auth = this.$_has('修改业务付款申请')
                    this.del_auth = this.$_has('删除业务付款申请')
                }
                this.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
            }
        },
    },
    filters:{
        diy_money(v) {
            return v ? thousandBitSeparator(fomatFloat(v)) : ''
        },
        diy_date(v) {
            return v ? v.slice(0,10) : ''
        }
    },
    props:{},
    created(){
        this.id = this.$route.query.id
        if(this.id) {
            this.get_business_payment_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>