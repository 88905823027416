import request from '../request'

//业务付款申请列表
export const business_payment_index_request = p => {
    return request({
        method:'GET',
        url:'business_payment/index',
        params: p
    })
}

//获取业务付款申请详情
export const business_payment_details_request = id => {
    return request({
        method:'GET',
        url:'business_payment/details',
        params: {
            id
        }
    })
}

// 提交业务付款申请
export const business_payment_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'business_payment/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看业务付款申请审批记录
export const business_payment_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'business_payment/sel_approval',
        params: {
            id
        }
    })
}

// 审批业务付款申请
export const business_payment_approval_request = d => {
    return request({
        method:'PUT',
        url:'business_payment/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other
        }
    })
}

// 撤回业务付款申请
export const business_payment_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'business_payment/withdraw',
        data: {
            id: d.id
        }
    })
}